/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
 * readers do not read off random characters that represent icons */

.icon-glass:before { content: $glass; }
.icon-music:before { content: $music; }
.icon-search:before { content: $search; }
.icon-envelope-alt:before { content: $envelope-alt; }
.icon-heart:before { content: $heart; }
.icon-star:before { content: $star; }
.icon-star-empty:before { content: $star-empty; }
.icon-user:before { content: $user; }
.icon-film:before { content: $film; }
.icon-th-large:before { content: $th-large; }
.icon-th:before { content: $th; }
.icon-th-list:before { content: $th-list; }
.icon-ok:before { content: $ok; }
.icon-remove:before { content: $remove; }
.icon-zoom-in:before { content: $zoom-in; }
.icon-zoom-out:before { content: $zoom-out; }
.icon-power-off:before,
.icon-off:before { content: $off; }
.icon-signal:before { content: $signal; }
.icon-gear:before,
.icon-cog:before { content: $cog; }
.icon-trash:before { content: $trash; }
.icon-home:before { content: $home; }
.icon-file-alt:before { content: $file-alt; }
.icon-time:before { content: $time; }
.icon-road:before { content: $road; }
.icon-download-alt:before { content: $download-alt; }
.icon-download:before { content: $download; }
.icon-upload:before { content: $upload; }
.icon-inbox:before { content: $inbox; }
.icon-play-circle:before { content: $play-circle; }
.icon-rotate-right:before,
.icon-repeat:before { content: $repeat; }
.icon-refresh:before { content: $refresh; }
.icon-list-alt:before { content: $list-alt; }
.icon-lock:before { content: $lock; }
.icon-flag:before { content: $flag; }
.icon-headphones:before { content: $headphones; }
.icon-volume-off:before { content: $volume-off; }
.icon-volume-down:before { content: $volume-down; }
.icon-volume-up:before { content: $volume-up; }
.icon-qrcode:before { content: $qrcode; }
.icon-barcode:before { content: $barcode; }
.icon-tag:before { content: $tag; }
.icon-tags:before { content: $tags; }
.icon-book:before { content: $book; }
.icon-bookmark:before { content: $bookmark; }
.icon-print:before { content: $print; }
.icon-camera:before { content: $camera; }
.icon-font:before { content: $font; }
.icon-bold:before { content: $bold; }
.icon-italic:before { content: $italic; }
.icon-text-height:before { content: $text-height; }
.icon-text-width:before { content: $text-width; }
.icon-align-left:before { content: $align-left; }
.icon-align-center:before { content: $align-center; }
.icon-align-right:before { content: $align-right; }
.icon-align-justify:before { content: $align-justify; }
.icon-list:before { content: $list; }
.icon-indent-left:before { content: $indent-left; }
.icon-indent-right:before { content: $indent-right; }
.icon-facetime-video:before { content: $facetime-video; }
.icon-picture:before { content: $picture; }
.icon-pencil:before { content: $pencil; }
.icon-map-marker:before { content: $map-marker; }
.icon-adjust:before { content: $adjust; }
.icon-tint:before { content: $tint; }
.icon-edit:before { content: $edit; }
.icon-share:before { content: $share; }
.icon-check:before { content: $check; }
.icon-move:before { content: $move; }
.icon-step-backward:before { content: $step-backward; }
.icon-fast-backward:before { content: $fast-backward; }
.icon-backward:before { content: $backward; }
.icon-play:before { content: $play; }
.icon-pause:before { content: $pause; }
.icon-stop:before { content: $stop; }
.icon-forward:before { content: $forward; }
.icon-fast-forward:before { content: $fast-forward; }
.icon-step-forward:before { content: $step-forward; }
.icon-eject:before { content: $eject; }
.icon-chevron-left:before { content: $chevron-left; }
.icon-chevron-right:before { content: $chevron-right; }
.icon-plus-sign:before { content: $plus-sign; }
.icon-minus-sign:before { content: $minus-sign; }
.icon-remove-sign:before { content: $remove-sign; }
.icon-ok-sign:before { content: $ok-sign; }
.icon-question-sign:before { content: $question-sign; }
.icon-info-sign:before { content: $info-sign; }
.icon-screenshot:before { content: $screenshot; }
.icon-remove-circle:before { content: $remove-circle; }
.icon-ok-circle:before { content: $ok-circle; }
.icon-ban-circle:before { content: $ban-circle; }
.icon-arrow-left:before { content: $arrow-left; }
.icon-arrow-right:before { content: $arrow-right; }
.icon-arrow-up:before { content: $arrow-up; }
.icon-arrow-down:before { content: $arrow-down; }
.icon-mail-forward:before,
.icon-share-alt:before { content: $share-alt; }
.icon-resize-full:before { content: $resize-full; }
.icon-resize-small:before { content: $resize-small; }
.icon-plus:before { content: $plus; }
.icon-minus:before { content: $minus; }
.icon-asterisk:before { content: $asterisk; }
.icon-exclamation-sign:before { content: $exclamation-sign; }
.icon-gift:before { content: $gift; }
.icon-leaf:before { content: $leaf; }
.icon-fire:before { content: $fire; }
.icon-eye-open:before { content: $eye-open; }
.icon-eye-close:before { content: $eye-close; }
.icon-warning-sign:before { content: $warning-sign; }
.icon-plane:before { content: $plane; }
.icon-calendar:before { content: $calendar; }
.icon-random:before { content: $random; }
.icon-comment:before { content: $comment; }
.icon-magnet:before { content: $magnet; }
.icon-chevron-up:before { content: $chevron-up; }
.icon-chevron-down:before { content: $chevron-down; }
.icon-retweet:before { content: $retweet; }
.icon-shopping-cart:before { content: $shopping-cart; }
.icon-folder-close:before { content: $folder-close; }
.icon-folder-open:before { content: $folder-open; }
.icon-resize-vertical:before { content: $resize-vertical; }
.icon-resize-horizontal:before { content: $resize-horizontal; }
.icon-bar-chart:before { content: $bar-chart; }
.icon-twitter-sign:before { content: $twitter-sign; }
.icon-facebook-sign:before { content: $facebook-sign; }
.icon-camera-retro:before { content: $camera-retro; }
.icon-key:before { content: $key; }
.icon-gears:before,
.icon-cogs:before { content: $cogs; }
.icon-comments:before { content: $comments; }
.icon-thumbs-up-alt:before { content: $thumbs-up-alt; }
.icon-thumbs-down-alt:before { content: $thumbs-down-alt; }
.icon-star-half:before { content: $star-half; }
.icon-heart-empty:before { content: $heart-empty; }
.icon-signout:before { content: $signout; }
.icon-linkedin-sign:before { content: $linkedin-sign; }
.icon-pushpin:before { content: $pushpin; }
.icon-external-link:before { content: $external-link; }
.icon-signin:before { content: $signin; }
.icon-trophy:before { content: $trophy; }
.icon-github-sign:before { content: $github-sign; }
.icon-upload-alt:before { content: $upload-alt; }
.icon-lemon:before { content: $lemon; }
.icon-phone:before { content: $phone; }
.icon-unchecked:before,
.icon-check-empty:before { content: $check-empty; }
.icon-bookmark-empty:before { content: $bookmark-empty; }
.icon-phone-sign:before { content: $phone-sign; }
.icon-twitter:before { content: $twitter; }
.icon-facebook:before { content: $facebook; }
.icon-github:before { content: $github; }
.icon-unlock:before { content: $unlock; }
.icon-credit-card:before { content: $credit-card; }
.icon-rss:before { content: $rss; }
.icon-hdd:before { content: $hdd; }
.icon-bullhorn:before { content: $bullhorn; }
.icon-bell:before { content: $bell; }
.icon-certificate:before { content: $certificate; }
.icon-hand-right:before { content: $hand-right; }
.icon-hand-left:before { content: $hand-left; }
.icon-hand-up:before { content: $hand-up; }
.icon-hand-down:before { content: $hand-down; }
.icon-circle-arrow-left:before { content: $circle-arrow-left; }
.icon-circle-arrow-right:before { content: $circle-arrow-right; }
.icon-circle-arrow-up:before { content: $circle-arrow-up; }
.icon-circle-arrow-down:before { content: $circle-arrow-down; }
.icon-globe:before { content: $globe; }
.icon-wrench:before { content: $wrench; }
.icon-tasks:before { content: $tasks; }
.icon-filter:before { content: $filter; }
.icon-briefcase:before { content: $briefcase; }
.icon-fullscreen:before { content: $fullscreen; }
.icon-group:before { content: $group; }
.icon-link:before { content: $link; }
.icon-cloud:before { content: $cloud; }
.icon-beaker:before { content: $beaker; }
.icon-cut:before { content: $cut; }
.icon-copy:before { content: $copy; }
.icon-paperclip:before,
.icon-paper-clip:before { content: $paper-clip; }
.icon-save:before { content: $save; }
.icon-sign-blank:before { content: $sign-blank; }
.icon-reorder:before { content: $reorder; }
.icon-list-ul:before { content: $list-ul; }
.icon-list-ol:before { content: $list-ol; }
.icon-strikethrough:before { content: $strikethrough; }
.icon-underline:before { content: $underline; }
.icon-table:before { content: $table; }
.icon-magic:before { content: $magic; }
.icon-truck:before { content: $truck; }
.icon-pinterest:before { content: $pinterest; }
.icon-pinterest-sign:before { content: $pinterest-sign; }
.icon-google-plus-sign:before { content: $google-plus-sign; }
.icon-google-plus:before { content: $google-plus; }
.icon-money:before { content: $money; }
.icon-caret-down:before { content: $caret-down; }
.icon-caret-up:before { content: $caret-up; }
.icon-caret-left:before { content: $caret-left; }
.icon-caret-right:before { content: $caret-right; }
.icon-columns:before { content: $columns; }
.icon-sort:before { content: $sort; }
.icon-sort-down:before { content: $sort-down; }
.icon-sort-up:before { content: $sort-up; }
.icon-envelope:before { content: $envelope; }
.icon-linkedin:before { content: $linkedin; }
.icon-rotate-left:before,
.icon-undo:before { content: $undo; }
.icon-legal:before { content: $legal; }
.icon-dashboard:before { content: $dashboard; }
.icon-comment-alt:before { content: $comment-alt; }
.icon-comments-alt:before { content: $comments-alt; }
.icon-bolt:before { content: $bolt; }
.icon-sitemap:before { content: $sitemap; }
.icon-umbrella:before { content: $umbrella; }
.icon-paste:before { content: $paste; }
.icon-lightbulb:before { content: $lightbulb; }
.icon-exchange:before { content: $exchange; }
.icon-cloud-download:before { content: $cloud-download; }
.icon-cloud-upload:before { content: $cloud-upload; }
.icon-user-md:before { content: $user-md; }
.icon-stethoscope:before { content: $stethoscope; }
.icon-suitcase:before { content: $suitcase; }
.icon-bell-alt:before { content: $bell-alt; }
.icon-coffee:before { content: $coffee; }
.icon-food:before { content: $food; }
.icon-file-text-alt:before { content: $file-text-alt; }
.icon-building:before { content: $building; }
.icon-hospital:before { content: $hospital; }
.icon-ambulance:before { content: $ambulance; }
.icon-medkit:before { content: $medkit; }
.icon-fighter-jet:before { content: $fighter-jet; }
.icon-beer:before { content: $beer; }
.icon-h-sign:before { content: $h-sign; }
.icon-plus-sign-alt:before { content: $plus-sign-alt; }
.icon-double-angle-left:before { content: $double-angle-left; }
.icon-double-angle-right:before { content: $double-angle-right; }
.icon-double-angle-up:before { content: $double-angle-up; }
.icon-double-angle-down:before { content: $double-angle-down; }
.icon-angle-left:before { content: $angle-left; }
.icon-angle-right:before { content: $angle-right; }
.icon-angle-up:before { content: $angle-up; }
.icon-angle-down:before { content: $angle-down; }
.icon-desktop:before { content: $desktop; }
.icon-laptop:before { content: $laptop; }
.icon-tablet:before { content: $tablet; }
.icon-mobile-phone:before { content: $mobile-phone; }
.icon-circle-blank:before { content: $circle-blank; }
.icon-quote-left:before { content: $quote-left; }
.icon-quote-right:before { content: $quote-right; }
.icon-spinner:before { content: $spinner; }
.icon-circle:before { content: $circle; }
.icon-mail-reply:before,
.icon-reply:before { content: $reply; }
.icon-github-alt:before { content: $github-alt; }
.icon-folder-close-alt:before { content: $folder-close-alt; }
.icon-folder-open-alt:before { content: $folder-open-alt; }
.icon-expand-alt:before { content: $expand-alt; }
.icon-collapse-alt:before { content: $collapse-alt; }
.icon-smile:before { content: $smile; }
.icon-frown:before { content: $frown; }
.icon-meh:before { content: $meh; }
.icon-gamepad:before { content: $gamepad; }
.icon-keyboard:before { content: $keyboard; }
.icon-flag-alt:before { content: $flag-alt; }
.icon-flag-checkered:before { content: $flag-checkered; }
.icon-terminal:before { content: $terminal; }
.icon-code:before { content: $code; }
.icon-reply-all:before { content: $reply-all; }
.icon-mail-reply-all:before { content: $mail-reply-all; }
.icon-star-half-full:before,
.icon-star-half-empty:before { content: $star-half-empty; }
.icon-location-arrow:before { content: $location-arrow; }
.icon-crop:before { content: $crop; }
.icon-code-fork:before { content: $code-fork; }
.icon-unlink:before { content: $unlink; }
.icon-question:before { content: $question; }
.icon-info:before { content: $info; }
.icon-exclamation:before { content: $exclamation; }
.icon-superscript:before { content: $superscript; }
.icon-subscript:before { content: $subscript; }
.icon-eraser:before { content: $eraser; }
.icon-puzzle-piece:before { content: $puzzle-piece; }
.icon-microphone:before { content: $microphone; }
.icon-microphone-off:before { content: $microphone-off; }
.icon-shield:before { content: $shield; }
.icon-calendar-empty:before { content: $calendar-empty; }
.icon-fire-extinguisher:before { content: $fire-extinguisher; }
.icon-rocket:before { content: $rocket; }
.icon-maxcdn:before { content: $maxcdn; }
.icon-chevron-sign-left:before { content: $chevron-sign-left; }
.icon-chevron-sign-right:before { content: $chevron-sign-right; }
.icon-chevron-sign-up:before { content: $chevron-sign-up; }
.icon-chevron-sign-down:before { content: $chevron-sign-down; }
.icon-html5:before { content: $html5; }
.icon-css3:before { content: $css3; }
.icon-anchor:before { content: $anchor; }
.icon-unlock-alt:before { content: $unlock-alt; }
.icon-bullseye:before { content: $bullseye; }
.icon-ellipsis-horizontal:before { content: $ellipsis-horizontal; }
.icon-ellipsis-vertical:before { content: $ellipsis-vertical; }
.icon-rss-sign:before { content: $rss-sign; }
.icon-play-sign:before { content: $play-sign; }
.icon-ticket:before { content: $ticket; }
.icon-minus-sign-alt:before { content: $minus-sign-alt; }
.icon-check-minus:before { content: $check-minus; }
.icon-level-up:before { content: $level-up; }
.icon-level-down:before { content: $level-down; }
.icon-check-sign:before { content: $check-sign; }
.icon-edit-sign:before { content: $edit-sign; }
.icon-external-link-sign:before { content: $external-link-sign; }
.icon-share-sign:before { content: $share-sign; }
.icon-compass:before { content: $compass; }
.icon-collapse:before { content: $collapse; }
.icon-collapse-top:before { content: $collapse-top; }
.icon-expand:before { content: $expand; }
.icon-euro:before,
.icon-eur:before { content: $eur; }
.icon-gbp:before { content: $gbp; }
.icon-dollar:before,
.icon-usd:before { content: $usd; }
.icon-rupee:before,
.icon-inr:before { content: $inr; }
.icon-yen:before,
.icon-jpy:before { content: $jpy; }
.icon-renminbi:before,
.icon-cny:before { content: $cny; }
.icon-won:before,
.icon-krw:before { content: $krw; }
.icon-bitcoin:before,
.icon-btc:before { content: $btc; }
.icon-file:before { content: $file; }
.icon-file-text:before { content: $file-text; }
.icon-sort-by-alphabet:before { content: $sort-by-alphabet; }
.icon-sort-by-alphabet-alt:before { content: $sort-by-alphabet-alt; }
.icon-sort-by-attributes:before { content: $sort-by-attributes; }
.icon-sort-by-attributes-alt:before { content: $sort-by-attributes-alt; }
.icon-sort-by-order:before { content: $sort-by-order; }
.icon-sort-by-order-alt:before { content: $sort-by-order-alt; }
.icon-thumbs-up:before { content: $thumbs-up; }
.icon-thumbs-down:before { content: $thumbs-down; }
.icon-youtube-sign:before { content: $youtube-sign; }
.icon-youtube:before { content: $youtube; }
.icon-xing:before { content: $xing; }
.icon-xing-sign:before { content: $xing-sign; }
.icon-youtube-play:before { content: $youtube-play; }
.icon-dropbox:before { content: $dropbox; }
.icon-stackexchange:before { content: $stackexchange; }
.icon-instagram:before { content: $instagram; }
.icon-flickr:before { content: $flickr; }
.icon-adn:before { content: $adn; }
.icon-bitbucket:before { content: $bitbucket; }
.icon-bitbucket-sign:before { content: $bitbucket-sign; }
.icon-tumblr:before { content: $tumblr; }
.icon-tumblr-sign:before { content: $tumblr-sign; }
.icon-long-arrow-down:before { content: $long-arrow-down; }
.icon-long-arrow-up:before { content: $long-arrow-up; }
.icon-long-arrow-left:before { content: $long-arrow-left; }
.icon-long-arrow-right:before { content: $long-arrow-right; }
.icon-apple:before { content: $apple; }
.icon-windows:before { content: $windows; }
.icon-android:before { content: $android; }
.icon-linux:before { content: $linux; }
.icon-dribbble:before { content: $dribbble; }
.icon-skype:before { content: $skype; }
.icon-foursquare:before { content: $foursquare; }
.icon-trello:before { content: $trello; }
.icon-female:before { content: $female; }
.icon-male:before { content: $male; }
.icon-gittip:before { content: $gittip; }
.icon-sun:before { content: $sun; }
.icon-moon:before { content: $moon; }
.icon-archive:before { content: $archive; }
.icon-bug:before { content: $bug; }
.icon-vk:before { content: $vk; }
.icon-weibo:before { content: $weibo; }
.icon-renren:before { content: $renren; }
